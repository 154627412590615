import React, { Component } from 'react';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Footer from "../component/footer/FooterTwo";
import HeaderThree from "../component/header/HeaderThree";
import Helmet from "../component/common/Helmet";
const image = require("./confused-john-travolta.gif")
class error404 extends Component {


    render() {
        return (
            <div className="active-dark">
                <Helmet pageTitle="Daniel Madureira" />
                <HeaderThree homeLink="/" logo="symbol-dark" color="color-black" />

                {/* Start Page Error  */}
                <div className="error-page-inner bg_color--4">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner">
                                    { /* <h1 className="title theme-gradient">404!</h1>*/}
                                    <img src={image}></img>
                                    <h3 className="sub-title">Página não Encontrada</h3>
                                    <div className="error-button">
                                        <a className="rn-button-style--2 btn-solid" href="/">Ínicio</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Error  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />
            </ div>
        )
    }
}
export default error404;
