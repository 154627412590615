import React from 'react';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import TextLoop from "react-text-loop";
import HeaderThree from "../component/header/HeaderThree";
import FooterTwo from "../component/footer/FooterTwo";
import TabTwo from "../elements/tab/TabTwo";
import ContactThree from "../elements/contact/ContactThree";
import ServiceList from "../elements/service/ServiceList";
import BlogContent from "../elements/blog/BlogContent";
import Portfolio from "../component/HomeLayout/homeOne/Portfolio";


const SlideList = [
    {
        textPosition: 'text-left',
        category: 'Seja bem vindo',
        description: '',
        buttonText: '',
        buttonLink: ''
    }
]
const PortfolioLanding = () => {
    let title = 'Sobre';
    const PostList = BlogContent.slice(0, 6).reverse();
    return (
        <div className="active-dark">
            <Helmet pageTitle="Daniel Madureira" />

            <HeaderThree homeLink="/" logo="symbol-dark" color="color-black" />
            {/* Start Slider Area   */}
            <div id="home" className="fix">
                <div className="slider-wrapper">
                    {/* Start Single Slide */}
                    {SlideList.map((value, index) => (
                        <div className="slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center bg_image bg_image--35" key={index}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className={`inner ${value.textPosition}`}>
                                            {value.category ? <span>{value.category}</span> : ''}
                                            <h1 className="title">Hi, I’m  Daniel<br />
                                                <TextLoop>
                                                    <span> Full Stack Developer.</span>
                                                    <span> Devops.</span>
                                                    <span> Data engineer.</span>
                                                    <span> Blockchain Developer.</span>
                                                </TextLoop>{" "}
                                            </h1>
                                            {value.description ? <p className="description">{value.description}</p> : ''}
                                            {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-primary-color" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    {/* End Single Slide */}
                </div>
            </div>
            {/* End Slider Area   */}

            {/* Start About Area */}
            <div id="about" className="fix">
                <div className="about-area ptb--120  bg_color--1">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/portfolio/familia_2.jpeg" alt="About Images" />
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">{title}</h2>
                                            <p className="description" style={{ textAlign: 'justify' }}>
                                                Me chamo Daniel, um apaixonado por código, desafios e inovação. Com 27 anos de idade, já acumulei quase 6 anos de experiência como desenvolvedor backend, explorando os intrincados caminhos do universo digital.
                                                <br />
                                                Além do meu amor pela programação, sou um orgulhoso marido e pai de dois filhos incríveis. A jornada da paternidade só acrescenta mais inspiração e motivação à minha busca constante por excelência profissional.
                                                <br />
                                                Atualmente, faço parte da equipe da Cryptum, uma empresa fascinante imersa no emocionante mundo das tecnologias blockchain. Aqui, contribuo para moldar o futuro da tecnologia, enfrentando desafios inovadores diariamente.
                                                <br />
                                                Minha jornada profissional é marcada pelo desejo constante de aprender, empreender e superar obstáculos. Este site é um reflexo do meu compromisso com o crescimento contínuo e a paixão por criar soluções impactantes.
                                            </p>
                                        </div>
                                        <div className="row mt--30">
                                            <TabTwo tabStyle="tab-style--1" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* End About Area */}

            {/* Start Service Area  */}
            <div id="service" className="fix">
                <div className="service-area creative-service-wrapper ptb--120 bg_color--5" >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                                    <h2 className="title">Serviços</h2>
                                    <p><i>"A sabedoria consiste em compreender que o tempo dedicado ao trabalho nunca é perdido."</i></p>
                                </div>
                            </div>
                        </div>
                        <div className="row creative-service">
                            <div className="col-lg-12">
                                <ServiceList item="6" column="col-lg-4 col-md-6 col-sm-6 col-12 text-left" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Service Area  */}

            {/* Portfolio */}
            <div id="portfolio" className="portfolio-area ptb--120 bg_color--1">
                <div className="portfolio-sacousel-inner mb--55">
                    <Portfolio />
                </div>
            </div>
            {/*End Portfolio */}

            {/* Start Blog Area */}
            <div id="blog" className="fix">
                <div className="rn-blog-area ptb--120 bg_color--5 mb-dec--30">
                    <div className="container">
                        <div className="row align-items-end">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="section-title text-center">
                                    <h2>Blog</h2>
                                    <p>O maior legado de um homem é o conhecimento que compartilhou com seus semelhantes durante a vida.<i><br />José Vitorelie</i></p>
                                </div>
                            </div>
                        </div>
                        <div className="row mt--60 mt_sm--40">
                            {PostList.map((value, i) => (
                                <div className="col-lg-4 col-md-6 col-12" key={i}>
                                    <div className="blog blog-style--1">
                                        <div className="thumbnail">
                                            <a href="/blog-details">
                                                <img className="w-100" src={`/assets/images/blog/blog-${value.images}.jpg`} alt="Blog Images" />
                                            </a>
                                        </div>
                                        <div className="content">
                                            <p className="blogtype">{value.category}</p>
                                            <h4 className="title"><a href="/blog-details">{value.title}</a></h4>
                                            <div className="blog-btn">
                                                <a className="rn-btn text-white" href="/blog-details">Read More</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {PostList.length > 6 &&
                            <div className="portfolio-button text-center">
                                <a className="rn-btn" href="/blog">+ Leia mais</a>
                            </div>
                        }
                    </div>
                </div>
            </div>
            {/* End Blog Area */}

            {/* Start COntact Area */}
            <div id="contact" className="fix">
                <div className="rn-contact-area ptb--120 bg_color--1">
                    <ContactThree contactImages="/assets/images/about/daniel-2.jpeg" contactTitle="Fale Comigo" />
                </div>
            </div>
            {/* End COntact Area */}

            <FooterTwo />
            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

        </div>
    )
}

export default PortfolioLanding;
