const BlogContent = [
    {
        images: '01',
        title: ' Indexador de blocos - Blockchain',
        category: 'Development'
    },
    {
        images: '02',
        title: 'Integração Chainlink com Cryptum Sdk ',
        category: 'Development'
    },
]

export default BlogContent;