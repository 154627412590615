import React ,{ Component }from "react";
import { FiCast , FiLayers , FiUsers , FiMonitor } from "react-icons/fi";
import { AiOutlineCloudServer, AiOutlineAppstore, AiOutlineApi } from  "react-icons/ai";
import { FaWhatsapp, FaRegClipboard } from "react-icons/fa"

const ServiceList = [
    {
        icon: <AiOutlineCloudServer />,
        title: 'Hospedagem  Vps',
        description: 'Hospedagem VPS dedicada com sites ilimitados, mais performance, estabilidade e disco SSD.'
    },
    {
        icon: <FiMonitor />,
        title: 'Desenvolvimento de  Sites',
        description: 'Criação de sites personalizados em Wordpress e React.js.'
    },
    { 
        icon: <AiOutlineAppstore />,
        title: 'Aplicacões Web',
        description: 'Desenvolvimento de aplicações web utilizando Node.js e React.js'
    },
    {
        icon: <FaWhatsapp />,
        title: 'WhatsApp',
        description: 'Integração de sistemas utilizando Whatsapp para envios de mensagens.'
    },
    {
        icon: <FaRegClipboard />,
        title: 'Extração de dados',
        description: 'Criação de ETL`s para extração de dados de diversas fontes utililizando python.'
    },
    { 
        icon: <AiOutlineApi />,
        title: 'Integrações entre sistemas',
        description: 'Integrações entre sistemas via api.'
    }
]


class ServiceThree extends Component{
    render(){
        const {column } = this.props;
        const ServiceContent = ServiceList.slice(0 , this.props.item);
        
        return(
            <React.Fragment>
                <div className="row">
                    {ServiceContent.map( (val , i) => (
                        <div className={`${column}`} key={i}>
                            <a >
                                <div className="service service__style--2">
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                        <p>{val.description}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
