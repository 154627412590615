import React, { Component } from "react";
import ReCAPTCHA from "react-google-recaptcha"
import PulseLoader from "react-spinners/PulseLoader";
class ContactThree extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rnName: '',
            rnEmail: '',
            rnSubject: '',
            rnMessage: '',
            rnStatus: '',
            message: '',
            color: '',
            token: '',
            loader: false
        };
    }
    render() {
        return (
            <div className="contact-form--1">
                <div className="container">
                    <div className="row row--35 align-items-start">
                        <div className="col-lg-6 order-2 order-lg-1">
                            <div className="section-title text-left mb--50">
                                <h2 className="title">{this.props.contactTitle}</h2>
                                <p className="description">Descubra como podemos ajudar a levar sua empresa ao próximo nível!</p>

                                <p className="description">Conecte-se comigo por telefone: <a href="tel:+5561982623910">(61) 98262-3910</a> ou email:
                                    <a href="mailto:admin@example.com"> support@danielmadureira.dev</a> </p>
                            </div>
                            <div className="form-wrapper">
                                <form>
                                    <label htmlFor="item01">
                                        <input
                                            type="text"
                                            name="name"
                                            id="item01"
                                            value={this.state.rnName}
                                            onChange={(e) => { this.setState({ rnName: e.target.value }); }}
                                            placeholder="Seu nome*"
                                        />
                                    </label>

                                    <label htmlFor="item02">
                                        <input
                                            type="text"
                                            name="email"
                                            id="item02"
                                            value={this.state.rnEmail}
                                            onChange={(e) => { this.setState({ rnEmail: e.target.value }); }}
                                            placeholder="Seu e-mail*"
                                        />
                                    </label>

                                    <label htmlFor="item03">
                                        <input
                                            type="text"
                                            name="subject"
                                            id="item03"
                                            value={this.state.rnSubject}
                                            onChange={(e) => { this.setState({ rnSubject: e.target.value }); }}
                                            placeholder="Assunto*"
                                        />
                                    </label>
                                    <label htmlFor="item04">
                                        <textarea
                                            type="text"
                                            id="item04"
                                            name="message"
                                            value={this.state.rnMessage}
                                            onChange={(e) => { this.setState({ rnMessage: e.target.value }); }}
                                            placeholder="Mensagem*"
                                        />
                                    </label>
                                    {this.state.rnStatus && <p style={{ color: this.state.color }}> {this.state.message}</p>}

                                    {this.state.token ?

                                        this.state.loader ? <PulseLoader loading={true} color="#27b859" /> :
                                            <button className="rn-button-style--2 btn-solid" type="button" name="submit" id="mc-embedded-subscribe"
                                                onClick={
                                                    () => {
                                                        this.setState({ loader: true });
                                                        fetch('/sendEmail', {
                                                            method: "POST",
                                                            body: JSON.stringify(this.state),
                                                            headers: { 'Content-Type': 'application/json' },
                                                        })
                                                            .then(res => res.json())
                                                            .then(json => {
                                                                if (json.status) {
                                                                    this.setState({
                                                                        rnName: '',
                                                                        rnEmail: '',
                                                                        rnSubject: '',
                                                                        rnMessage: '',
                                                                        rnStatus: json.status,
                                                                        message: "Email enviado com sucesso!",
                                                                        color: "green"
                                                                    })
                                                                } else {
                                                                    this.setState({
                                                                        rnStatus: true,
                                                                        message: json.message || "Não foi possível enviar seu E-mail. Por favor, Tente novamente mais tarde.",
                                                                        color: "red"
                                                                    })
                                                                }
                                                                this.setState({ loader: false });
                                                            }).catch(e => this.setState({ loader: false }))
                                                    }
                                                }>Enviar</button>
                                        :
                                        <ReCAPTCHA sitekey="6LeznFcaAAAAAIy9aL7yjHtEd8AuO9VEM00sp9Pv" onChange={
                                            (value) => {
                                                setTimeout(() =>
                                                    this.setState({ token: value })
                                                    , 2000)

                                            }
                                        } />
                                    }
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2">
                            <div className="thumbnail mb_md--30 mb_sm--30">
                                <img src={`${this.props.contactImages}`} alt="trydo" style={{ marginTop: '120px' }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ContactThree;