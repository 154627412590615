import React from "react";
import Slider from "react-slick";
import { portfolioSlick2 } from "../../../page-demo/script";


const PortfolioList = [
    {
        image: 'image-1',
        category: 'API',
        title: ' Projeto em python para extração de dados em sistemas diversos.'
    },
    {
        image: 'image-1',
        category: 'API',
        title: 'Multdesk - Sistema para gestão de atendimentos'
    },
    {
        image: 'image-2',
        category: 'Integração',
        title: ' Integração entre os sistemas Anota Ai e Ecomanda'
    },
    {
        image: 'image-3',
        category: 'PIX',
        title: ' Projeto para automação de pagamentos e recebimentos com PIX'
    },
    {
        image: 'image-4',
        category: 'Development',
        title: ' Chatbot automatizado utilizando dialogflow'
    },
    {
        image: 'image-5',
        category: 'DW',
        title: ' Modelagem e construção de um Data Warehouse'
    },
    {
        image: 'image-6',
        category: 'ApFix',
        title: ' Sistema para controle de manutenções em condominios'
    },
    {
        image: 'image-7',
        category: 'Whatsapp',
        title: ' Integração de sistemas com Whatsapp'
    },
    {
        image: 'image-8',
        category: 'API',
        title: ' Integração entre sistemas'
    }
]

const Portfolio = () => {

        let title = 'Projetos',
        description = '"Você nunca sabe que resultados virão da sua ação. Mas se você não fizer nada, não existirão resultados."';
        
        return(
            <React.Fragment >
                <div className="portfolio-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h2>{title}</h2>
                                    <p>{description}<br /><i>Mahatma Gandhi</i></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="portfolio-slick-activation mt--70 mt_sm--40">
                        <Slider {...portfolioSlick2}>
                            {PortfolioList.map((value , index) => (
                                <div className="portfolio" key={index}>
                                    <div className="thumbnail-inner">
                                        <div className={`thumbnail ${value.image}`}></div>
                                        <div className={`bg-blr-image ${value.image}`}></div>
                                    </div>
                                    <div className="content">
                                        <div className="inner">
                                            <p>{value.category}</p>
                                            <h4><a href="/portfolio-details">{value.title}</a></h4>
                                            <div className="portfolio-button">
                                                <a className="rn-btn" href="/portfolio-details">+ Detalhes</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </React.Fragment>
        )
}
export default Portfolio;