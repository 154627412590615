import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

class TabsTwo extends Component {
    render() {
        let
            tab1 = "Skills",
            tab2 = "Experiência",
            tab3 = "Educação"
        const { tabStyle } = this.props
        return (
            <div>
                {/* Start Tabs Area */}
                <div className="tabs-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Tabs>
                                    <TabList className={`${tabStyle}`}>
                                        <Tab>{tab1}</Tab>
                                        <Tab>{tab2}</Tab>
                                        <Tab>{tab3}</Tab>
                                    </TabList>

                                    <TabPanel>
                                        <div className="single-tab-content">
                                            <ul>
                                                <li>
                                                    <a href="#about">Frontend <span> - Developer</span></a>
                                                    React.js, HTML, CSS, javaScript
                                                </li>
                                                <li>
                                                    <a href="#about">Backend<span> - Developer</span></a>
                                                    API`s, python, node.js, php, java
                                                </li>
                                                <li>
                                                    <a href="#about">Infraestrutura<span></span></a>
                                                    Blockchain, Docker, Linux, AWS, Git
                                                </li>
                                            </ul>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="single-tab-content">
                                            <ul>
                                                <li>
                                                    <a href="https://cryptum.io/">Dveloper<span> - Cryptum</span></a> 2023 - Current
                                                </li>
                                                <li>
                                                    <a href="http://site.bsbdisbrave.com.br">Sogtware Engineer<span> - BSB Disbrave</span></a> 2020 - 2022
                                                </li>
                                                <li>
                                                    <a href="https://letsupscale.com">Cofundador<span> - Upscale</span></a> 2020 - 2021
                                                </li>
                                                <li>
                                                    <a href="https://www.cabal.com.br/pt/">Estagiário<span> - Cabal Brasil </span></a> 2019 - 2020
                                                </li>
                                                <li>
                                                    <a href="https://www2.tc.df.gov.br/">Estagiário<span> - TCDF</span></a> 2017 - 2019
                                                </li>
                                            </ul>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="single-tab-content">
                                            <ul>
                                                <li>
                                                    <a href="#about">Ciência da Computação<span> - Centro Universitário IESB, Brasília, DF</span></a> 2021
                                                </li>
                                                <li>
                                                    <a href="#about">Ciência de Dados<span> - Universidade Cruzeiro do Sul, Brasília, DF</span></a> 2021
                                                </li>
                                            </ul>
                                        </div>
                                    </TabPanel>

                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tabs Area */}
            </div>
        )
    }
}



export default TabsTwo;